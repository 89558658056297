import React, {useState}from "react";
import { connect } from "react-redux";
import { getUserRole } from "../utils/utils";
import { Input, Form, message } from 'antd';
import clsx from "clsx";


function UserProfilePage(props) {
  const {user, auth} = props;
  const serviceConfig = window.ServiceConfig;
  const [loading, setLoading ] = useState(false);

  const color = ['inf-badge--unified-brand', 'inf-badge--unified-dark', 'inf-badge--unified-primary', 'inf-badge--unified-success', 'inf-badge--unified-info', 'inf-badge--unified-warning', 'inf-badge--unified-danger'];
  const get_random  =(list) => {
    return list[Math.floor((Math.random()*list.length))];
  }

  const onFinish = (values) => {
    setLoading(true);
    var postData = "{\n"
        postData += "    \"AccessToken\" : \""+ auth.accessToken +"\",\n"
        postData += "    \"PreviousPassword\" : \""+ values.old_password + "\",\n"
        postData += "    \"ProposedPassword\" : \"" + values.confirm_password + "\"\n"
        postData += "}\n"
    let region = serviceConfig.UserPoolId.split('_')[0];
    var url = "https://cognito-idp."+ region +".amazonaws.com/";
    var request = new XMLHttpRequest();

    request.open("POST", url, false /* async */);
    request.setRequestHeader("Content-Type", "application/x-amz-json-1.1");
    request.setRequestHeader("x-amz-target", "AWSCognitoIdentityProviderService.ChangePassword");
    try {
      request.send(postData);
    } catch (error) {
      setLoading(false);
    }

    if (request.status === 200) {
      message.success('password changed !');
      setLoading(false);
    } else {
      var resp = JSON.parse(request.responseText);
      var err_msg = resp.message;
      if (request.responseText.includes("Incorrect username or password")) {
        err_msg = "Incorrect old password";
      }
      message.error('Failed to change password. Error: ' + err_msg);
      setLoading(false);
    }
  };

  return(
    <>
      <div className="inf-portlet">
        <div className="inf-portlet__body">
          <div className="inf-widget4">
            <div className="inf-widget4__item ">
              <div className="inf-widget4__pic inf-widget4__pic--pic ">
                <span className={`inf-badge inf-badge--username inf-badge--use-list inf-badge--rounded inf-badge--bold ${get_random(color)}`}>
                  {user.userName &&(user.userName.substr(0, 2).toUpperCase())}
                </span>
              </div>
              <div className="inf-widget4__info ">
                <span className="inf-widget4__username">{user.userName}</span>
                <div className="d-flex flex-wrap">
                  <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                    <span className="flaticon2-box mr-1" />
                    ArtifactUri : {user.customerArtifactUri}
                  </span> 
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap border-bot">
              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon2-user icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">Username</span>
                  <span className="font-weight-bolder font-size-h5">{user.userName}</span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon-businesswoman icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">Role </span>
                  <span className="font-weight-bolder font-size-h5">{getUserRole(user)}</span>
                </div>
              </div>
          
              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon2-delivery-package icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">Service</span>
                  <span className="font-weight-bolder font-size-h5">{serviceConfig.Service}</span>
                </div>
              </div>
          
              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon2-website icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm"> Service Version </span>
                  <span className="font-weight-bolder font-size-h5"> {user.serviceVersion} </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon-security icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm"> PresignedUrl For InfinSnap </span>
                  <span className={`font-weight-bolder font-size-h5 btn btn-xsm 
                    ${clsx({"btn-label-success": (user.usePresignedUrlForInfinSnap === "true")})}
                    ${clsx({"btn-label-danger": (user.usePresignedUrlForInfinSnap === "false")})}`}>
                      {user.usePresignedUrlForInfinSnap === "false" ? "Disabled ":" Enabled "} 
                  </span>
                </div>
              </div>
          
              <div className="d-flex align-items-center flex-lg-fill my-1">
                <span className="mr-4">
                  <i className="flaticon-security icon-2x text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">PresignedUrl For Mlflow</span>
                  <span className={`font-weight-bolder font-size-h5 btn btn-xsm 
                    ${clsx({"btn-label-success": (user.usePresignedUrlForMLflow === "true")})}
                    ${clsx({"btn-label-danger": (user.usePresignedUrlForMLflow === "false")})}`}>
                      {user.usePresignedUrlForMLflow === "false" ? "Disabled ":" Enabled "} 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="inf-portlet inf-portlet--height-fluid">
            <div className="inf-portlet__head">
              <div className="inf-portlet__head-label">
                <h3 className="inf-portlet__head-title">Change Password</h3>
              </div>
            </div>
            <div className="inf-portlet__body">            
              <div style={{width: 500, padding:10}}> 
                <Form name="basic" requiredMark={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
                  <Form.Item label="Old password" className="form-lable" name="old_password"
                    rules={[ { required: true, message: 'Please input your current password!'}]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item label="New password" className="form-lable" name="new_password"
                    rules={[
                      { required: true, message: 'Please input your password!' },
                      () => ({
                        validator(_, value) {
                          if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(value)) {
                            const passError = 'The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.';
                            return Promise.reject(new Error(passError));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item label="Confirm password" className="form-lable" name="confirm_password" dependencies={['new_password']}
                    rules={[
                      { required: true, message: 'Please input your confirm password!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
                    <button className={`btn btn-sm btn-label-brand type='submit'
                      ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": loading})}`}> 
                      Change Password 
                    </button> 
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { user, auth }= state;
  return {user, auth }
}

export default connect(mapStateToProps, null)(UserProfilePage);
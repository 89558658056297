import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputModal } from "../../layout/InputModal";
import { CreateGroupForm } from './CreateGroupForm';
import { groupNameValidator } from '../../utils/Validator';
import { createGroupApi, listCognitoGroupApi } from '../../store/actions';
import cogoToast from 'cogo-toast';

export class CreateGroupModalmpl extends Component {
  handleCreateGroup = async (values) => {
    const result = await this.props.createGroupApi(values).catch(e=> {
      cogoToast.error("Something went wrong, Please try again..");
    })
    if(result.value){
      cogoToast.success('Successfully created group');
      this.props.history.push(`/group/${values.group_name}`)
    } else {
      cogoToast.error("Something went wrong, Please try again..");
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <InputModal
        title='Create new group'
        okText='Create'
        isOpen={isOpen}
        handleSubmit={this.handleCreateGroup}
        onClose={this.props.onClose}
      >
        <CreateGroupForm nameValidator={groupNameValidator} group={{}}/>
      </InputModal>
    );
  }
}

const mapDispatchToProps = {
  createGroupApi,
  listCognitoGroupApi
};

export const CreateGroupModal = connect(null, mapDispatchToProps)(CreateGroupModalmpl);
